import {
    useState, useEffect
} from 'react'
import base64 from 'react-native-base64'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom'

export default function Login() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection("usuarios")
    })

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
  
    // Example to get a specific query param
    const ref = searchParams.get('ref');
    const idref = searchParams.get('idref');

    const [cpf, setCpf] = useState('')
    const [validaSenha, setValidaSenha] = useState(false)

    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [dica, setDica] = useState('')

    const [emailResponsavel, setEmailResponsavel] = useState('')
    const [senhaResponsavel, setSenhaResponsavel] = useState('')
    const [dicaResponsavel, setDicaResponsavel] = useState('')

    function recuperaSenha() {
        if (!cpf) {
            mensagemAlerta('Favor digite seu CPF')
            return
        }
        if (cpf.length < 11) {
            mensagemAlerta('CPF incompleto')
            return
        }
        mensagemLoading('Enviando email...')
        servicos.usuarios.where('cpf', '==', cpf).get().then((snap) => {
            if (snap.size === 0) {
                Swal.close();
                mensagemAlerta('CPF não consta em nosso sistema! Verifique novamente ou entre em contato com a Administração')
            } else {
                Swal.close();
                const email = snap.docs.map((d) => d.data())[0].email
                servicos.auth.sendPasswordResetEmail(email).then(() => {
                    mensagemSucesso('Email enviado com sucesso para ' + email + ' ! Verifique sua caixa de email e faça os passos para recuperação de senha ')
                }).catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    Swal.close()
                    mensagemErro('Erro ao enviar email '+errorMessage)
                });
            }
        })
    }

    function login() {
        if (email === '') {
            mensagemAlerta('Digite o Email')
        } else if (senha === '') {
            mensagemAlerta('Digite a senha')
        } else {
            mensagemLoading('Realizando login')
            servicos.auth.signInWithEmailAndPassword(email, senha).then(() => {
                servicos.auth.onAuthStateChanged((usuario) => {
                    servicos.usuarios.doc(usuario.uid).get().then((doc) => {
                        if (doc.data().usuario === 2) {
                            
                            if(ref === 'evento'){
                                window.location = `/evento/${idref}`
                            }
                            else{
                                window.location = `/filiacao/${idref}`
                            }

                        } else {
                            let texto = ''
                            switch (doc.data().usuario) {
                                case 1: texto = 'Você está tentando acessar como <b>Competidor</b>, porém esta conta é do tipo <b>Administrador</b>. Se você deseja alterar o tipo de sua conta para <b>Competidor</b> e poder se inscrever em eventos, por favor, entre em contato com a Administração.'; break;
                                case 3: texto = 'Você está tentando acessar como <b>Competidor</b>, porém esta conta é do tipo <b>Responsável</b>. Para acessar corretamente, insera seus dados no campo da direita >>> <b>Login Responsável</b>'; break;
                            
                                default: texto = 'Você está tentando acessar como <b>Competidor</b>, porém esta conta NÃO é do tipo <b>Competidor</b>. Se você deseja alterar o tipo de sua conta para <b>Competidor</b>, por favor, entre em contato com a Administração.'; break;
                            }
                            servicos.auth.signOut().then(() => {
                                mensagemAtencao(texto)
                            })
                        }
                    })
                });
            }).catch((error) => {
                var errorCode = error.code;
                if (error) {
                    Swal.close();
                    if (errorCode === 'auth/invalid-email') {
                        mensagemAlerta('Email incompleto ou não é válido')
                    } else if (errorCode === 'auth/user-not-found') {
                        mensagemAlerta('Email não cadastrado em nosso Sistema! Cadastre-se ou verifique novamente')
                    } else if (errorCode === 'auth/wrong-password') {
                        mensagemAlerta('Senha Incorreta')
                    }
                }
            });
        }
    }

    function loginResponsavel() {
        if (emailResponsavel === '') {
            mensagemAlerta('Digite o email')
        } else if (senhaResponsavel === '') {
            mensagemAlerta('Digite a senha')
        } else {
            mensagemLoading('Realizando login')
            servicos.auth.signInWithEmailAndPassword(emailResponsavel, senhaResponsavel).then(() => {
                servicos.auth.onAuthStateChanged((usuario) => {
                    servicos.usuarios.doc(usuario.uid).get().then((doc) => {
                        if (doc.data().usuario === 3) {
                            if(ref === 'evento'){
                                window.location = `/evento/${idref}`
                            }
                            else{
                                window.location = `/filiacao/${idref}`
                            }
                        } else {
                            let texto = ''
                            switch (doc.data().usuario) {
                                case 1: texto = 'Você está tentando acessar como <b>Responsável</b>, porém esta conta é do tipo <b>Administrador</b>. Se você deseja alterar o tipo de sua conta para <b>Responsável</b> e poder inscrever seus alunos, por favor, entre em contato com a Administração.'; break;
                                case 2: texto = 'Você está tentando acessar como <b>Responsável</b>, porém esta conta é do tipo <b>Competidor</b>. Se você deseja alterar o tipo de sua conta para <b>Responsável</b> e poder inscrever seus alunos, por favor, entre em contato com a Administração.'; break;
                            
                                default: texto = 'Você está tentando acessar como <b>Responsável</b>, porém esta conta NÃO é do tipo <b>Responsável</b>. Se você deseja alterar o tipo de sua conta para <b>Responsável</b> e poder inscrever seus alunos, por favor, entre em contato com a Administração.'; break;
                            }
                            servicos.auth.signOut().then(() => {
                                mensagemAtencao(texto)
                            })
                        }
                    })
                });
            }).catch((error) => {
                var errorCode = error.code;
                if (error) {
                    Swal.close();
                    if (errorCode === 'auth/invalid-email') {
                        mensagemAlerta('Email incompleto ou não é válido')
                    } else if (errorCode === 'auth/user-not-found') {
                        mensagemAlerta('Email não cadastrado em nosso Sistema! Cadastre-se ou verifique novamente')
                    } else if (errorCode === 'auth/wrong-password') {
                        mensagemAlerta('Senha Incorreta')
                    }
                }
            });
        }
    }

    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    const handleCpf = (event) => {
        event.persist();
        setCpf(event.target.value);
    }
    const handleEmailResponsavel = (event) => {
        event.persist();
        setEmailResponsavel(event.target.value);
    }
    const handleSenhaResponsavel = (event) => {
        event.persist();
        setSenhaResponsavel(event.target.value);
    }


    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            setValidaSenha(false)
            setCpf('')
        })
    }
    function mensagemSucessoEmail(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {
            window.location = '/'
        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemAtencao(msg) {
        Swal.fire({
            title: 'Atenção',
            html: msg,
            icon: 'warning'
        })
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />

                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        {validaSenha ?
                                            <div class="wpb_column vc_column_container  vc_col-sm-4">
                                                <div class="vc_column-inner ">
                                                    <div class="wpb_wrapper">
                                                        <div class="wpb_widgetised_column wpb_content_element">
                                                            <div class="wpb_wrapper">
                                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                    <h2>Recupera Senha</h2>
                                                                </div>
                                                                <p>Digite seu seu CPF. Será enviado um email de recuperação de senha em seu Email cadastrado</p>
                                                                <input
                                                                    type="text" name="your-name" size="40"
                                                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                    aria-required="true" aria-invalid="false"
                                                                    onChange={handleCpf}
                                                                    value={cpf}
                                                                    placeholder="CPF" />
                                                                <div onClick={() => recuperaSenha()} style={{ marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-12">
                                                                    <input type="submit" value="Recuperar Senha" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                </div>
                                                                <a href="javascript:void(0)" onClick={() => setValidaSenha(false)} style={{ marginTop: 10 }} class="form-submit__btn-wrapp vc_col-sm-12">
                                                                    <h6 style={{ textAlign: "center", marginTop: 20 }}>Possui login? Clique aqui</h6>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div class="wpb_text_column wpb_content_element ">

                                                <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                                </div>
                                                <div class="wpb_column vc_column_container  vc_col-sm-6">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div class="wpb_widgetised_column wpb_content_element">
                                                                <div class="wpb_wrapper">
                                                                    <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                        <h2>Login Competidor</h2>
                                                                    </div>
                                                                    <input
                                                                        type="text" name="your-name" size="40"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                        aria-required="true" aria-invalid="false"
                                                                        onChange={handleEmail}
                                                                        value={email}
                                                                        placeholder="Email" />

                                                                    <input
                                                                        style={{ marginTop: 20 }}
                                                                        type="password" name="your-name" size="40"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                        aria-required="true" aria-invalid="false"
                                                                        onChange={handleSenha}
                                                                        value={senha}
                                                                        placeholder="Senha" />
                                                                    {dica ?
                                                                        <p style={{ textAlign: "center", marginTop: 20 }}>Dica: {dica}</p>
                                                                        :
                                                                        null
                                                                    }
                                                                    <div onClick={() => login()} style={{ marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-12">
                                                                        <input type="submit" value="Login" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                    </div>
                                                                    <a href="/cadastroCompetidor" style={{ marginTop: 10 }} class="form-submit__btn-wrapp vc_col-sm-12">
                                                                        <h6 style={{ textAlign: "center", marginTop: 20 }}>Não possui login? Cadastre-se</h6>
                                                                    </a>
                                                                    <a href="javascript:void(0)" onClick={() => setValidaSenha(true)} style={{ marginTop: 10 }} class="form-submit__btn-wrapp vc_col-sm-12">
                                                                        <h6 style={{ textAlign: "center", marginTop: 20 }}>Esqueceu a senha? Clique aqui</h6>
                                                                    </a>
                                                                    <a href="/mudaEmail" style={{ marginTop: 10 }} class="form-submit__btn-wrapp vc_col-sm-12">
                                                                        <h6 style={{ textAlign: "center", marginTop: 20 }}>Esqueceu Email? Clique aqui</h6>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="wpb_column vc_column_container  vc_col-sm-6">
                                                    <div class="vc_column-inner ">
                                                        <div class="wpb_wrapper">
                                                            <div class="wpb_widgetised_column wpb_content_element">
                                                                <div class="wpb_wrapper">
                                                                    <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                        <h2>Como Responsável</h2>
                                                                    </div>
                                                                    <input
                                                                        type="text" name="your-name" size="40"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                        aria-required="true" aria-invalid="false"
                                                                        onChange={handleEmailResponsavel}
                                                                        value={emailResponsavel}
                                                                        placeholder="Email" />

                                                                    <input
                                                                        style={{ marginTop: 20 }}
                                                                        type="password" name="your-name" size="40"
                                                                        class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                        aria-required="true" aria-invalid="false"
                                                                        onChange={handleSenhaResponsavel}
                                                                        value={senhaResponsavel}
                                                                        placeholder="Senha" />
                                                                    {dica ?
                                                                        <p style={{ textAlign: "center", marginTop: 20 }}>Dica: {dica}</p>
                                                                        :
                                                                        null
                                                                    }
                                                                    <div onClick={() => loginResponsavel()} style={{ marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-12">
                                                                        <input type="submit" value="Login" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                                    </div>
                                                                    <a href="/cadastroResponsavel" style={{ marginTop: 10 }} class="form-submit__btn-wrapp vc_col-sm-12">
                                                                        <h6 style={{ textAlign: "center", marginTop: 20 }}>Não possui login? Cadastre-se</h6>
                                                                    </a>
                                                                    <a href="javascript:void(0)" onClick={() => setValidaSenha(true)} style={{ marginTop: 10 }} class="form-submit__btn-wrapp vc_col-sm-12">
                                                                        <h6 style={{ textAlign: "center", marginTop: 20 }}>Esqueceu a senha? Clique aqui</h6>
                                                                    </a>
                                                                    <a href="/mudaEmail" style={{ marginTop: 10 }} class="form-submit__btn-wrapp vc_col-sm-12">
                                                                        <h6 style={{ textAlign: "center", marginTop: 20 }}>Esqueceu Email? Clique aqui</h6>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
