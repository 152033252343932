import moment from 'moment'
import { useEffect, useState } from 'react';
import firebase from 'firebase';
import './Footer.css'
import logo from './Imagens/logo.png';
import whatsapp from './Imagens/whatsapp.png';


export default function Footer() {

    const [configuracoes, setConfiguracoes] = useState({});
    const [servicos] = useState({
        configuracoes: firebase.firestore().collection("configuracoes")
    })

    useEffect(()=>{
        servicos.configuracoes.get().then((snap =>{
            const config = snap.docs.map(c => c.data())
            if(config[0]){
                setConfiguracoes(config[0]);
            }
        }))
    },[])


    return (
        <>

<div class="whatsapp-icon">
  <a href={`https://api.whatsapp.com/send?phone=55${configuracoes?.whatsapp?.numero}&text=${configuracoes?.whatsapp?.mensagem}`} target="_blank" title='WhatsApp'>
    <img src={whatsapp} alt="WhatsApp Icon"/>
  </a>
</div>


          {/* <!-- Footer --> */}

          <footer id="main-footer" className="py-2">
    <div className="container footer-container">
      <div style={{display:"inline-flex", flexDirection:"column", paddingTop: "10px"}} >
        <img src={logo} alt="" style={{margin:"10px 0 10px 0"}}/>
        <p>O Site PROCompetidor foi criado com o objetivo de dar apoio, transparência e organização a <br></br> organizadores e atletas em eventos esportivos.</p>
      </div>
      <div>
        <h3 style={{marginLeft:"90px"}}>Redes Sociais</h3>
        <ul class="list">

        <div class="social">
   
          <li>
            
            <a href="https://www.instagram.com/procompetidor/" target="_blank" style={{marginLeft:"90px"}}><i class="fab fa-instagram fa-2x"></i></a>
            <a href="https://www.facebook.com/profile.php?id=100089234715425" target="_blank" style={{marginLeft:"10px"}}><i class="fab fa-facebook fa-2x"></i></a>

          </li>
          
          </div>
        </ul>
      </div>
      <div>
        <h3>Links Importantes</h3>
        <ul class="list">
          {/* <li><a href="/">Início</a></li> */}
          <li><a href="/eventos?p=andamento">Eventos</a></li>
          <li><a href="/federacoes">Filiações</a></li>
          <li><a href="/ranking">Rankings</a></li>
          <li><a href="/contato">Contato</a></li>
          {/* <li><a href="/sobre">Sobre</a></li> */}
        </ul>
      </div>
      <div>
        <p><span> Procompetidor {moment().format("YYYY")} &copy; Todos os Direitos Reservados </span></p>
      </div>
    </div>
    <div className='termos' >
    <a>Termos de uso</a> | 
    <a> Política de Privacidade</a>  
  </div>
  </footer>

        </>
    );
}