import {
    useState, useEffect, useRef
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import { geraPreferencia } from '../service/MercadoPago'


export default function Evento() {
    const ref = useRef(null);
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos"),
        equipes: firebase.firestore().collection("equipes"),
        usuarios: firebase.firestore().collection("usuarios"),
        inscricoes: firebase.firestore().collection('inscricoes'),
        auth: firebase.auth()
    })
    const [id] = useState(window.location.pathname.replace('/pagamentoAluno/', ''))
    const [inscricao, setIncricao] = useState({})
    const [evento, setEvento] = useState({})
    const [usuario, setUsuario] = useState({})
    const [url, setUrl] = useState('')

const mediaQuery = window.matchMedia('(max-width: 450px)');

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            let idUser = JSON.parse(localStorage.getItem('usuarioId'))
            if (user || idUser) {
                mensagemLoading('Carregando dados para pagamento..')
                servicos.inscricoes.doc(id).get().then((snap) => {
                    const data = snap.data()
                    setIncricao(snap.data())
                    setUsuario(data.usuario)
                    servicos.eventos.doc(data.evento.id).get().then((d) => {
                        setEvento(d.data())

                        const pagamentosExcluidos = []

                        switch (d.data().formaPagamento) {
                            case 0:
                            case 8: pagamentosExcluidos.push(
                                { id: "digital_wallet" },
                                { id: "digital_currency" },
                                { id: "debit_card" });
                                break;
                            case 1:
                            case 7: pagamentosExcluidos.push(
                                { id: "digital_wallet" },
                                { id: "digital_currency" },
                                { id: "debit_card" },
                                { id: "ticket" });
                                break;
                            case 2: pagamentosExcluidos.push(
                                { id: "digital_wallet" },
                                { id: "digital_currency" },
                                { id: "debit_card" },
                                { id: "bank_transfer" },
                                { id: "credit_card" });
                                break;
                            case 5:
                            case 3: pagamentosExcluidos.push(
                                { id: "digital_wallet" },
                                { id: "digital_currency" },
                                { id: "debit_card" },
                                { id: "ticket" },
                                { id: "credit_card" });
                                break;
                            case 6: pagamentosExcluidos.push(
                                { id: "digital_wallet" },
                                { id: "digital_currency" },
                                { id: "debit_card" },
                                { id: "credit_card" });
                                break;
                        }

                        const dados = {
                            items: [
                                {
                                    id: data.id,
                                    title: data.status === 2 ? data.categoria.absoluto.absoluto.toUpperCase() + " - (" + data.evento.nome + ")" : data.categoria.categoria.categoria.toUpperCase() + " - (" + data.evento.nome + ")",
                                    description: "Evento realizado pela equipe Procompetidor",
                                    picture_url: data.avatar,
                                    category_id: "electronics",
                                    quantity: 1,
                                    currency_id: "R$",
                                    unit_price: data.status === 2 ? parseFloat(data.valorAbsoluto) : parseFloat(data.valorTotal),
                                }
                            ],
                            payment_methods: {
                                excluded_payment_types: pagamentosExcluidos,
                                installments: 2
                            },
                            payer: {
                                name: data.usuario.nome,
                                surname: data.usuario.sobrenome,
                                email: data.usuario.email,
                            },
                            auto_return: "all",
                            external_reference: data.id,
                            back_urls: {
                                success: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                pending: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                failure: "https://procompetidor.com.br/minhaInscricao/" + data.id,
                                // success: "http://localhost:3000/minhaInscricao/" + data.id,
                                // pending: "http://localhost:3000/minhaInscricao/" + data.id,
                                // failure: "http://localhost:3000/minhaInscricao/" + data.id,
                            },
                            notification_url: "https://us-central1-procompetidor-7867b.cloudfunctions.net/app/notificacao?source_news=ipn",
                            statement_descriptor: "PRO COMPETIDOR",

                        }
                        const element = ref.current;
                        geraPreferencia(dados, d.data().chavePagamento, element.value).then((doc) => {
                            setUrl(doc.init_point);
                            Swal.close();
                        })

                        Swal.close();
                    })
                })

            } else {
                window.location = '/'
            }

        })
    }, [])

    function pagamento() {
        window.location = window.location.href.replace('/pagamentoAluno/' + id, '/pagamentoManualAluno/' + id)
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(() => {

        })
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function valorTotal(comDesconto) {
        let valorTotal = 0;

        if ((inscricao.pagamento && (inscricao?.pagamento?.status === 'pending' || inscricao?.pagamento?.status === null))) {
            valorTotal += inscricao?.valorCategoria;
        }

        if (inscricao.pagamentoAbsoluto && (inscricao?.pagamentoAbsoluto?.status === 'pending' || inscricao?.pagamentoAbsoluto?.status === null)) {
            valorTotal += inscricao?.valorAbsoluto;
        }

        if (comDesconto) {
            if (inscricao.voucher && inscricao?.voucher?.tipo === "desconto") {
                valorTotal -= valorTotal * (parseFloat(inscricao.voucher.porcentagem) / 100);
            } else if (inscricao.voucher && inscricao.voucher.tipo === "valor") {
                valorTotal -= parseFloat(inscricao.voucher.valor)
            }
        }

        if (
            (inscricao.voucher && inscricao.voucher.id) && (
                ((inscricao.pagamento && (inscricao?.pagamento?.status === 'approved'))
                    && (inscricao.pagamentoAbsoluto && (inscricao?.pagamentoAbsoluto?.status === 'pending' || inscricao?.pagamentoAbsoluto?.status === null)))
            )) {
            return inscricao.valorAbsoluto;
        }

        return valorTotal;

    }

    function calculaValorTotal() {
        return inscricao?.valorCategoria + inscricao?.valorAbsoluto
    }
    function exibirDescontoFederacao() {
        return (
            inscricao?.descontoFiliacao
                ?.descontoAplicado
        )
    }

    return (
        <div className="body-wrapper blank-loder">
            <input ref={ref} type="hidden" id="deviceId"></input>
            <Header />
            <div class="section_fullwidth">
                <div class="headline headline_img">
                    <div class="headline__inner">
                        <div class="headline__title">
                            <h1>Formas de pagamento</h1>
                        </div>
                        <div class="headline__description">
                            <span>Para realizar sua inscrição, escolha as formas de pagamentos.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_default section-single-class-layout clearfix">
                <main class="section_default">
                    <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                        </div>
                                        {/* <div class=" vc_col-sm-4">
                                            <figure class="wpb_wrapper vc_figure" style={{ width: '100%' }}>
                                                <div class="package-style-1__img">
                                                    <img src={evento.avatar}
                                                        alt="" title="price-img" /></div>
                                            </figure>
                                            <figure class="wpb_wrapper vc_figure" style={{ width: '100%' }}>
                                                <div class="package-style-1__descr  package-style-1_with-img">
                                                    <h3 class="package-style-1__title" style={{ color: "#121212" }}>{evento.nome} </h3>
                                                    <div class="package-style-1__text">
                                                        {evento.inicioEvento === evento.fimEvento ?
                                                            <div class="package-style-1__text"><i class="fa  fa-calendar" aria-hidden="true"
                                                                style={{ color: "#c21111" }}></i> Dia {moment(evento.inicioEvento).format("DD/MM/YYYY")}</div>
                                                            :
                                                            <div class="package-style-1__text"><i class="fa  fa-calendar" aria-hidden="true"
                                                                style={{ color: "#c21111" }}></i> De {moment(evento.inicioEvento).format("DD/MM/YYYY")} à {moment(evento.fimEvento).format("DD/MM/YYYY")}</div>
                                                        }
                                                        <div class="package-style-1__text"><i class="fa  fa-map-marker" aria-hidden="true"
                                                            style={{ color: "#c21111" }}></i> {evento.endereco ? evento.endereco.cidade : null} / {evento.endereco ? evento.endereco.uf : null}</div>
                                                    </div>
                                                    <div class="package-style-1__price clearfix">
                                                        <div class="package-style-1__price-amount" style={{ color: "#121212" }}><span
                                                            class="package-style-1__price-currency"></span><span
                                                                class="package-style-1__price-value extra-field-1-target"
                                                                data-extra-field-1-def="20">
                                                                {inscricao.voucher.id ?
                                                                    <>
                                                                        {inscricao.voucher.tipo === "desconto" ?
                                                                            <div class="package-style-1__text" style={{ marginTop: 20, fontSize: 18, color: 'green' }}><i class="fa fa-money" aria-hidden="true"></i> <b>R$ {(parseFloat(inscricao.valorCategoria) + parseFloat(inscricao.valorAbsoluto)) - ((parseFloat(inscricao.valorCategoria) + parseFloat(inscricao.valorAbsoluto)) * parseFloat(inscricao.voucher.porcentagem) / 100)}</b></div>
                                                                            // <td><h6 style={{ color: "green" }}>R$ {(parseFloat(inscricao.valorCategoria) + parseFloat(inscricao.valorAbsoluto)) - ((parseFloat(inscricao.valorCategoria) + parseFloat(inscricao.valorAbsoluto)) * parseFloat(inscricao.voucher.porcentagem) / 100)}</h6></td>
                                                                            : inscricao.voucher.tipo === "valor" ?
                                                                                <div class="package-style-1__text" style={{ marginTop: 20, fontSize: 18, color: 'green' }}><i class="fa fa-money" aria-hidden="true"></i> <b>R$ {(parseFloat(inscricao.valorCategoria) + parseFloat(inscricao.valorAbsoluto)) - parseFloat(inscricao.voucher.valor)}</b></div>
                                                                                // <td><h6 style={{ color: "green" }}>R$ {(parseFloat(inscricao.valorCategoria) + parseFloat(inscricao.valorAbsoluto)) - parseFloat(inscricao.voucher.valor)}</h6></td>
                                                                                :
                                                                                null
                                                                        }
                                                                    </>
                                                                    :
                                                                    <td><b>R$ {inscricao.valorTotal}</b></td>
                                                                }
                                                            </span></div>

                                                    </div>
                                                </div>
                                            </figure>
                                            <figure class="wpb_wrapper vc_figure">
                                                <div class="package-style-1__img">
                                                    <img src={usuario.avatar}
                                                        alt="" title="price-img" /></div>
                                                <div class="package-style-1__descr  package-style-1_with-img">
                                                    <h3 class="package-style-1__title" style={{ color: "#121212" }}>{usuario.nome} {usuario.sobrenome}</h3>
                                                    <div class="package-style-1__text">
                                                        <div class="package-style-1__text"><i class="fa  fa-file" aria-hidden="true"
                                                            style={{ color: "#c21111" }}></i> CPF {usuario.cpf} </div>
                                                        <div class="package-style-1__text"><i class="fa  fa-user" aria-hidden="true"
                                                            style={{ color: "#c21111" }}></i> {usuario.sexo}</div>
                                                    </div>
                                                </div>
                                            </figure>
                                        </div> */}
                                        <div class="wpb_column vc_column_container vc_col-sm-4">
                                            <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_widgetised_column wpb_content_element">
                                                        <div class="wpb_wrapper">
                                                            <div role="form" class="row " >
                                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>
                                                                    <figure class="wpb_wrapper vc_figure" style={{ width: '100%' }}>
                                                                        <div class="package-style-1__img">
                                                                            <img src={evento.avatar}
                                                                                alt="" title="price-img" /></div>
                                                                    </figure>
                                                                </div>
                                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                    <h3 class="package-style-1__title" style={{ color: "#121212" }}>{evento.nome} </h3>
                                                                    {evento.inicioEvento === evento.fimEvento ?
                                                                        <div class="package-style-1__text"><i class="fa  fa-calendar" aria-hidden="true"
                                                                            style={{ color: "#c21111" }}></i> Dia {moment(evento.inicioEvento).format("DD/MM/YYYY")}</div>
                                                                        :
                                                                        <div class="package-style-1__text"><i class="fa  fa-calendar" aria-hidden="true"
                                                                            style={{ color: "#c21111" }}></i> De {moment(evento.inicioEvento).format("DD/MM/YYYY")} à {moment(evento.fimEvento).format("DD/MM/YYYY")}</div>
                                                                    }
                                                                    <div class="package-style-1__text"><i class="fa  fa-map-marker" aria-hidden="true"
                                                                        style={{ color: "#c21111" }}></i> {evento.endereco ? evento.endereco.cidade : null} / {evento.endereco ? evento.endereco.uf : null}</div>
                                                                    {/* <td><b>R$ {valorTotal(true)}</b></td> */}
                                                                    {inscricao.status === 2 ? (
                                                                        <td><b>{inscricao.valorAbsoluto ? inscricao.valorAbsoluto.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : ''}</b></td>
                                                                    )
                                                                        :
                                                                        (
                                                                            <td><b>{inscricao.valorTotal ? inscricao.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : ''}</b></td>
                                                                        )
                                                                    }

                                                                </div>
                                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                                    <div class="package-style-1__img">
                                                                        <img src={usuario.avatar}
                                                                            alt="" title="price-img" /></div>
                                                                    <h3 class="package-style-1__title" style={{ color: "#121212" }}>{usuario.nome} {usuario.sobrenome}</h3>
                                                                    <div class="package-style-1__text">
                                                                        <div class="package-style-1__text"><i class="fa  fa-file" aria-hidden="true"
                                                                            style={{ color: "#c21111" }}></i> CPF {usuario.cpf} </div>
                                                                        <div class="package-style-1__text"><i class="fa  fa-user" aria-hidden="true"
                                                                            style={{ color: "#c21111" }}></i> {usuario.sexo}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="wpb_column vc_column_container vc_col-sm-8">
                                            <div class="vc_column-inner ">
                                                <div class="wpb_wrapper">
                                                    <div class="wpb_widgetised_column wpb_content_element">
                                                        <div class="wpb_wrapper">
                                                            <div role="form" class="row " >
                                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>
                                                                    <h3>Categorias</h3>
                                                                    {inscricao.categoria ?
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr >
                                                                                    <th class="schedule-table__day">Categoria</th>
                                                                                    <th class="schedule-table__day">Peso</th>
                                                                                    <th class="schedule-table__day">Faixa</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}><h6>{inscricao.categoria.categoria.categoria}</h6></td>
                                                                                    <td><h6>{inscricao.categoria.peso.tipoPeso === 0 ? "Até " + inscricao.categoria.peso.peso + " Kg" : "Acima " + inscricao.categoria.peso.peso + " Kg"}</h6></td>
                                                                                    <td><h6>{inscricao.categoria.faixa.faixa}</h6></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        null
                                                                    }
                                                                    {inscricao.categoria && inscricao.categoria.absoluto.id ?
                                                                        <table style={{ width: "100%", marginTop: 20 }}>
                                                                            <thead>
                                                                                <tr >
                                                                                    <th class="schedule-table__day">Absoluto</th>
                                                                                    <th class="schedule-table__day">Idade</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}><h6>{inscricao.categoria.absoluto.absoluto}</h6></td>
                                                                                    <td><h6>{inscricao.categoria.categoria.tipoIdade === 0 ? "Até " + inscricao.categoria.categoria.idade + " anos" : inscricao.categoria.categoria.tipoIdade === 1 ? "De " + inscricao.categoria.categoria.minIdade + " até " + inscricao.categoria.categoria.maxIdade + " anos" : "Acima de " + inscricao.categoria.categoria.idade}</h6></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        :
                                                                        null
                                                                    }
                                                                    <table style={{ width: "100%", marginTop: 20 }}>
                                                                        <thead>
                                                                            <tr >
                                                                                <th class="schedule-table__day">Extrato</th>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                    <table style={{ width: "100%" }}>
                                                                        <tbody>
                                                                            {(inscricao.pagamento && (inscricao?.pagamento?.status === 'pending' || inscricao?.pagamento?.status === null)) && (
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}>Valor da Categoria</td>
                                                                                    <td>{inscricao.pagamento ? inscricao.valorCategoria.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : null}</td>
                                                                                </tr>
                                                                            )}
                                                                            {(inscricao.pagamentoAbsoluto && (inscricao?.pagamentoAbsoluto?.status === 'pending' || inscricao?.pagamentoAbsoluto?.status === null)) && (
                                                                                <tr>
                                                                                    <td style={{ height: 50 }}>Valor Absoluto</td>
                                                                                    <td>{inscricao.pagamento ? inscricao.valorAbsoluto.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : null}</td>
                                                                                </tr>
                                                                            )}



                                                                            {inscricao.status !== 2 && (
                                                                                <>
                                                                                    {(inscricao?.voucher?.id && inscricao?.voucher?.tipo === "desconto") && (
                                                                                        <tr>
                                                                                            <td style={{ height: 50 }}><h6 >Voucher de {inscricao?.voucher?.tipo} aplicado</h6></td>

                                                                                            <td><h6>- {(calculaValorTotal() * parseFloat(inscricao?.voucher?.porcentagem) / 100).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} ({inscricao?.voucher?.porcentagem}%)</h6></td>
                                                                                        </tr>
                                                                                    )}
                                                                                    {(inscricao?.voucher?.id && inscricao?.voucher?.tipo === "cortesia") && (
                                                                                        <tr>
                                                                                            <td style={{ height: 50 }}><h6 >Voucher de {inscricao?.voucher?.tipo} aplicado</h6></td>

                                                                                            <td><h6>GRÁTIS</h6></td>
                                                                                        </tr>
                                                                                    )}
                                                                                    {(inscricao?.voucher?.id && inscricao?.voucher?.tipo === "valor") && (
                                                                                        <tr>
                                                                                            <td style={{ height: 50 }}><h6 >Voucher de {inscricao?.voucher?.tipo} aplicado</h6></td>

                                                                                            <td><h6 >- {inscricao?.voucher?.valor ? parseFloat(inscricao?.voucher?.valor).toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : ''}</h6></td>
                                                                                        </tr>
                                                                                    )}

                                                                                    {
                                                                                        ((exibirDescontoFederacao() && (!inscricao?.voucher?.tipo === "cortesia")) ||
                                                                                            (exibirDescontoFederacao() && (!inscricao?.voucher?.id)) ||
                                                                                            (exibirDescontoFederacao() && (['valor', 'desconto'].includes(inscricao?.voucher?.tipo))))
                                                                                        && (
                                                                                            <tr>
                                                                                                <td style={{ height: 50 }}><h6>Desconto de filiação aplicado</h6></td>
                                                                                                {inscricao?.descontoFiliacao
                                                                                                    ?.tipoDesconto === '1' ? (
                                                                                                    <td><h6>- {inscricao?.descontoFiliacao?.valorDescontoAplicado.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} ({inscricao?.descontoFiliacao?.valorDesconto}%)</h6></td>
                                                                                                ) :
                                                                                                    (
                                                                                                        <td><h6>- {parseFloat(inscricao?.descontoFiliacao?.valorDescontoAplicado).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h6></td>
                                                                                                    )
                                                                                                }
                                                                                            </tr>
                                                                                        )}
                                                                                    {true && (
                                                                                        <tr>
                                                                                            <td style={{ height: 50 }}><h6 style={{ color: (inscricao?.voucher?.id || exibirDescontoFederacao()) ? 'green' : '' }}>{(inscricao?.voucher?.id || exibirDescontoFederacao()) ? 'Valor Total com desconto' : 'Valor Total'}</h6></td>

                                                                                            <td><h6 style={{ color: (inscricao?.voucher?.id || exibirDescontoFederacao()) ? 'green' : '' }}>{inscricao.valorTotal ? inscricao.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }) : ''}</h6></td>

                                                                                        </tr>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {/* <div>
                                                                    <h1>Importante</h1>
                                                                    <h3>Pagamentos via Boleto</h3>
                                                                    <p>Após gerar o boleto de pagamento , clique no botão de <b>"Voltar para o site"</b> ou aguarde<br /> 5 segundos para ser redirecionado a nossa<br /> tela de configuração na tela do<br /> <b>MERCADO PAGO</b> para gerar suas<br /> credenciais de pagamento no <b>Procompetidor.</b></p>
                                                                    <p>Aguarde a mensagem <b>"Aguarde.. Verificando Pagamento"</b> finalizar para confirmar suas<br /> credenciais  </p>
                                                                    <h3>Pagamentos via PIX</h3>
                                                                    <p>Após gerar o código PIX de pagamento ,<br /> clique no botão de <b>"Voltar para o site"</b> ser redirecionado a nossa tela de configuração na tela do <b>MERCADO PAGO</b> para gerar suas<br /> credenciais de pagamento no <b>Procompetidor.</b></p>
                                                                    <p>Aguarde a mensagem <b>"Aguarde.. Verificando Pagamento"</b> finalizar para confirmar suas<br /> credenciais  </p>
                                                                    <h3>Pagamentos via Cartão</h3>
                                                                    <p>Após confirmar o pagamento ,<br /> você ser redirecionado após 5 segundos a nossa tela de configuração para gerar suas<br /> credenciais de pagamento no <b>Procompetidor.</b></p>
                                                                    <p>Aguarde a mensagem <b>"Aguarde.. Finalizando Inscrição"</b> finalizar para confirmar suas<br /> credenciais  </p>
                                                                </div> */}
                                                                {/* {url ?
                                                                    <a href={url} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill " >Pagar</a>
                                                                    // <iframe width={"100%"} height="700" src={url.replace("redirect","modal")} />
                                                                    :
                                                                    null
                                                                } */}
                                                                {url ?
                                                                    <>
                                                                        {evento && evento.id && [0, 4, 5, 7].includes(evento?.formaPagamento) ?
                                                                            <>
                                                                                <a href={url} target={mediaQuery.matches? '_blank' : '_self'} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill " >Pagamento Online</a>
                                                                                <a href="javascript:void(0)" onClick={() => pagamento()} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill " >Pagamento direto ao Administrador</a>
                                                                            </>
                                                                            :
                                                                            <a href={url} target={mediaQuery.matches? '_blank' : '_self'} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill " >Pagamento Online</a>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {evento && evento.id && [0, 4, 5, 7].includes(evento?.formaPagamento) &&
                                                                            (<a href="javascript:void(0)" onClick={() => pagamento()} style={{ width: "100%", marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill " >Pagamento direto ao Administrador</a>)

                                                                        }
                                                                    </>
                                                                }
                                                                <a href={`/evento/${evento.id}`} style={{ width: "50%", marginTop: "20px", marginLeft: "25%", background: "#17a2b8" }} class="form-submit__btn-wrapp btn_defoult btn_fill " ><i class="fa fa-arrow-left"></i> Voltar</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
            <Footer />
        </div >
    );
}
